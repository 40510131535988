import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

import { StaticQuery, graphql } from 'gatsby';
import Menu from '../components/menu';
import { renderRecipeCovers } from '../components/recipeCoversList';

export const query = graphql`
  query MainPageCovers {
    allPrismicRecipe(limit: 6, sort: {fields: data___publication_date, order: DESC}) {
      edges {
        node {
          data {
            name
            slug
            serves
            cover_image {
              alt
              copyright
              url
              fluid {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
      }
    }
  }
`;

const IndexPage = () => (
  <Layout>
    <SEO title="Strona główna" />
    <header>
      <Menu></Menu>
    </header>
    <h1 className="section-header">Najnowsze</h1>
    <StaticQuery query={query} render={renderRecipeCovers} />
  </Layout>
);

export default IndexPage;
