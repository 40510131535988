import React from 'react';
import { Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import getRecipeSlug from '../utils/getRecipeSlug';
import Img from 'gatsby-image';

const renderCover = (recipe) => {
  const { name, slug } = recipe.data;
  const { cover_image } = recipe.data;

  if (cover_image) {
    const image = cover_image && cover_image.fluid;
    return (
      <div className="recipe" key={recipe.id}>
        <Link to={getRecipeSlug(slug)}>
          <BackgroundImage fluid={image} className="cover"></BackgroundImage>
          <h2>{name}</h2>
        </Link>
      </div>
    );
  }

  return (
    <div className="recipe">
      <h2>{name}</h2>
    </div>
  );
};

export const renderRecipeCovers = (data) => {
  const recipes = data.allPrismicRecipe.edges.map((recipe) => recipe.node);
  return (
    <div className="recipes-covers-container">{recipes.map(renderCover)}</div>
  );
};

const renderMobileCover = (recipe) => {
  const { name, slug } = recipe.data;
  const { cover_image } = recipe.data;

  if (cover_image) {
    const image = cover_image && cover_image.fluid;
    return (<div className="recipe mobile" key={recipe.id}>
      <Link to={getRecipeSlug(slug)} className="mobile-recipe-link">
        <Img fluid={image} className="mini-image" />
        <h2>{name}</h2>
      </Link>
    </div>)
  }

  return (
    <div className="recipe" key={recipe.id}>
      <Link to={getRecipeSlug(slug)}>
        <h2>{name}</h2>
      </Link>
    </div>
  );
};

export const renderMobileCovers = (data) => {
  const recipes = data.allPrismicRecipe.edges.map((recipe) => recipe.node);
  return (
    <div className="recipes-covers-container mobile">{recipes.map(renderMobileCover)}</div>
  );
}